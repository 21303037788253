export default class Utils{

    static timeSince(ts){
        let now = new Date();
        ts = new Date(ts*1000);
        var delta = now.getTime() - ts.getTime();
    
        delta = delta/1000; //us to s
    
        var ps, pm, ph, pd, min, hou, sec, days;
        
        if(delta<=59){
            ps = (delta>1) ? "": "";
            return Math.round(delta)+" seg"+ps
        }
    
        if(delta>=60 && delta<=3599){
            min = Math.floor(delta/60);
            sec = delta-(min*60);
            pm = (min>1) ? "": "";
            ps = (sec>1) ? "": "";
            return min+" min"+pm+" ";
        }
    
        if(delta>=3600 && delta<=86399){
            hou = Math.floor(delta/3600);
            min = Math.floor((delta-(hou*3600))/60);
            ph = (hou>1) ? "s": "";
            pm = (min>1) ? "s": "";
            return hou+" hr"+ph+" ";
        } 
    
        if(delta>=86400 && delta <= 2591999){
            days = Math.floor(delta/86400);
            hou =  Math.floor((delta-(days*86400))/60/60);
            pd = (days>1) ? "s": "";
            ph = (hou>1) ? "s": "";
            return days+" día"+pd+" ";
        }

        if(delta>=2592000){
            days = Math.floor(delta/2592000);
            hou =  Math.floor((delta-(days*2592000))/60/60);
            pd = (days>1) ? "es": "";
            ph = (hou>1) ? "es": "";
            return days+" mes"+pd+" ";
        }
    
    }


    static getNowDate() {
        const new_date = new Date();
        if (new_date instanceof Date && !isNaN(new_date)) {
        //   let dateArray = new Array();
        //   dateArray[0] = data.substring(0, 4);
        //   dateArray[1] = data.substring(5, 7);
        //   dateArray[2] = data.substring(8, 10);
  
          let date = new Date();
  
          let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
          );
          let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
          );
          let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
          let newDate = `${ye}-${mo.charAt(0).toUpperCase() + mo.slice(1)}-${da}`;
          return newDate;
        }
        return "";
    }


    //format > dd/mm/yyyy
    static globalDateFormat(data) {
        const new_date = new Date(data);
        if (new_date instanceof Date && !isNaN(new_date)) {
            let dateArray = new Array();
            dateArray[0] = data.substring(0, 4);
            dateArray[1] = data.substring(5, 7);
            dateArray[2] = data.substring(8, 10);

            let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

            let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
            );
            let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
            );
            let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
            let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
            return newDate;
        }
        return data;
    }


}