<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div
                            class="text-uppercase font-weight-bold primary--text text-h5"
                        >
                            Editar proyecto
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <proyecto-form
                                ref="projectoForm"
                                :values="form"
                                @save="save"
                            >
                            </proyecto-form>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-row justify="center" align="end">
                <v-col cols="12" md="5" class="text-right">
                    <v-btn
                        class="full-width-btn"
                        tile
                        text
                        color="primary"
                        :to="{ name: 'Proyectos' }"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        tile
                        elevation="0"
                        class="ml-md-2 ml-0 mt-md-0 mt-3 btn-yellow full-width-btn text-base-color"
                        @click="save"
                    >
                        Guardar
                    </v-btn>
                </v-col>
            </v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import proyectoForm from "./Form.vue";
export default {
    components: {
        "proyecto-form": proyectoForm,
    },
    data() {
        return {
            id: this.$route.params.id,
            loading: false,
            form: {
                code: "",
                description: "",
                business: "",
            },
            snackbarProps: {
                visible: false,
                color: "success",
                timeout: 3000,
                text: "",
            },
        };
    },
    mounted() {
        this.index();
    },
    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        index() {
            this.$store.state.overlay = true;
            this.loading = true;
            this.$http
                .get(
                    this.$store.state.apiRoute +
                        "/projects/" +
                        this.id +
                        "/edit"
                )
                .then((response) => {
                    this.form = {
                        code: response.data.key,
                        description: response.data.description,
                        business: response.data.business,
                    };

                    console.log(this.form);
                })
                .catch((error) => {
                    error;
                })
                .finally(() => {
                    this.$store.state.overlay = false;
                    this.loading = false;
                });
        },
        save() {
            if (this.validate()) {
                this.$store.state.overlay = true;

                const form = this.prepareRequest(this.form);

                this.$http
                    .put(
                        this.$store.state.apiRoute +
                            "/projects/" +
                            this.id +
                            "/update",
                        form
                    )
                    .then(() => {
                        this.$store.state.globalSnackbarProps.visible = true;
                        this.$store.state.globalSnackbarProps.text =
                            "Sea ha registrado correctamente";
                        this.$router.push({ name: "Proyectos" });
                    })
                    .catch((error) => {
                        var errors = [];
                        switch (error.response.status) {
                            case 422:
                                Object.keys(error.response.data.errors).forEach(
                                    (x) => {
                                        error.response.data.errors[x].forEach(
                                            (y) => {
                                                errors.push(y);
                                            }
                                        );
                                    }
                                );
                                break;
                            case 500:
                                errors.push(
                                    "Error en el servidor. Intente de nuevo."
                                );
                                break;
                            case 404:
                                errors.push("No encontrado");
                                break;
                        }

                        this.snackbarProps = {
                            visible: true,
                            color: "error",
                            timeout: 10000,
                            text: errors.join(".<br>"),
                        };
                    })
                    .finally(() => {
                        this.$store.state.overlay = false;
                    });
            } else {
                this.snackbarProps = {
                    visible: true,
                    color: "error",
                    timeout: 2000,
                    text: "Formulario incompleto",
                };
                this.$store.state.overlay = false;
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request) {
            let data = JSON.parse(JSON.stringify(request));

            data.key = data.code;

            return data;
        },
    },
};
</script>