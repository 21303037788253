<template>
    <div>
        <v-dialog ref="dialog" v-model="picker.visible" :return-value.sync="dateValue" persistent :width="picker.width">
            <template v-slot:activator="{ on }">
                <v-text-field :loading="loading" :disabled="disabled" v-model="value" :label="picker.label" :append-icon="picker.icon" readonly v-on="on" :rules="rules"></v-text-field>
            </template>
            <v-date-picker v-model="value" scrollable color="primary">
                <v-spacer></v-spacer>
                <v-btn text :color="picker.btn.cancelColor" @click="picker.visible = false">Cancelar</v-btn>
                <v-btn text :color="picker.btn.okColor" @click="$refs.dialog.save(value)">Aceptar</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        props: ['picker', 'dateVal', 'rules', 'disabled', 'loading'],
        data(){
            return {
                dateValue: ''
            }
        },
        computed: {
            value: {
                get: function() {
                    return this.dateVal
                },
                set: function(value) {
                    this.$emit('setDate', value)
                }
            }
        },
        watch: {
            dateVal(){
                this.dateValue = this.dateVal
            },
            dateValue(){
                this.$emit('setDate', this.dateValue)
            }
        }
    }
</script>