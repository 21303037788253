<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Nombre *" v-model="values.name" :rules="rules.name"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="RFC" v-model="values.rfc" counter="13" :rules="[...rules.required, ...rules.rfc]"></v-text-field>
            </v-col>
        </v-row>
         <v-row>
            <v-col class="py-0">
                <v-autocomplete :items="serverItems.origins" label="Origen" v-model="values.origin" :rules="rules.required"  append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Contacto *" v-model="values.contact " :rules="rules.contact"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Teléfono" counter="10" v-model="values.phone " :rules="rules.phone"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Correo electrónico " v-model="values.email " :rules="rules.email"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete :loading="loadingBusiness"  multiple :items="serverItems.businesses" label="Empresas a las que provee" v-model="values.business" :rules="rules.atLeastOne"  append-icon="mdi-chevron-down">
                     <template v-slot:selection="data">
                        <v-chip
                            class="mt-4 mb-1"
                            color="primary"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data)"
                            
                        >
                        <span class="white--text"> {{ data.item.text }}</span>
                        </v-chip>
                    </template>
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggle"
                        >
                        
                        <v-list-item-content>
                            <v-list-item-title>
                            Seleccionar todos/Remover todos
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
      
    </div>
</template>

<script>
export default {
    name: 'proveedor-form',
    props: ['values'],
    data(){
        return {
            loadingBusiness: false,
            serverItems:{
                origins: [
                    {text: "Nacional", value: "Nacional"},
                    {text: "Internacional", value: "Internacional"},
                ],
                businesses: []
            },

            rules: {
                name: [
                    v => !!v || 'Nombre es requerido'
                ],
                rfc: [
                    v => (v.length == 0 || (v.length >= 12  && v.length <= 13)) || 'RFC debe contener de 12 a 13 carácteres'
                ],
                required: [
                    v => !!v || 'El campo es requerido'
                ],
                atLeastOne: [
                    v => (!!v || v?.length > 0) || 'El campo es requerido'
                ],
                neighborhood: [
                    v => !!v || 'Colonia es requerido'
                ],
                street: [
                    v => !!v || 'Calle es requerido'
                ],
                ext_number: [
                    v => !!v || 'No. Ext. es requerido',
                    v => /^[0-9]*$/.test(v) || 'No. Interior sólo debe contener números',
                ],
                int_number: [],
                contact: [
                    v => !!v || 'Contacto es requerido'
                ],
                phone:[
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(/^[0-9]*$/.test(v) == false){
                            return 'Teléfono sólo debe contener números';
                        }
                        if(''+v.length > 10)
                            return 'Teléfono debe contener 10 digitos';
                        
                        return true;
                    }
                ],
                // phone: [
                //     v => !!v || true,
                //     v => /^[0-9]*$/.test(v) || 'Teléfono sólo debe contener números',
                //     v => (v.length == 0 || v.length == 10) || 'Teléfono debe contener 10 digitos'
                // ],
                email: [
                    v => (v.length == 0 || /.+@.+\..+/.test(v)) || 'Correo debe ser con formato válido',
                ],
            },
        }
    },
    computed: {
        selAll () {
            return this.serverItems.businesses.length === this.values.business.length
        },
    },
    watch: {
        ["values.business"]: function (v) {
            
            if(v.length == this.serverItems.businesses.length){
                this.values.type_business = 'all';
               
            }else{
                this.values.type_business = 'single';
               
            }
            
        },
        
    },
    mounted() {
        this.getBusiness();
    },
    methods: {
        toggle () {
            this.$nextTick(() => {
                if (this.selAll) {
                    this.values.business = []
                } else {
                    this.values.business = this.serverItems.businesses.map((itm) => {return itm.value})
                }
                console.log(this.values.business)
            })
        },
        getBusiness()
        {
            this.loadingBusiness = true;
            this.serverItems.businesses = [];
            this.$http.get(this.$store.state.flujoRoute+'/external/business')
            .then((response) => {
                console.log(response.data)
                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    if(this.$store.state.userData.business.includes(element.value)){
                        this.serverItems.businesses.push({text: element.name, value: element.value});
                    }
                }
                console.log(this.serverItems.businesses)

               
            })
            .finally(() => {this.loadingBusiness = false;});
        },
    }
}
</script>

<style>

</style>