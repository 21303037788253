<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="left-center" cols="12" md="8">
                        <v-row>
                            <v-col cols="12" md="3" lg="3"  class="text-center text-md-left px-0">
                                <div class="text-uppercase font-weight-bold primary--text text-h6">
                                    <span>Revisar requisición</span>
                                </div>
                            </v-col>

                            <v-col class="px-0 mx-0">
                                <v-row justify="center" align="end">
                                    <v-col cols="12" class="text-right">
                                        <v-btn class='full-width-btn ' elevation="0"  color="transparent" @click="back()">
                                            Regresar
                                        </v-btn>
                                        <v-btn class='full-width-btn ml-0 ml-md-2 my-3 my-md-0' elevation="0" color="red" @click="openRejectModal()" >
                                            <span class="white--text">Rechazar</span>
                                        </v-btn>
                                        <v-btn class='full-width-btn ml-0 ml-md-2 my-3 my-md-0' elevation="0" color="success" @click="openApproveModal()">
                                            <span class="white--text">Aprobar</span>
                                        </v-btn>
                                        <v-btn class='full-width-btn ml-0 ml-md-2' elevation="0" color="success" @click="save()">
                                            <span class="white--text">Guardar</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="mx-0 px-0 py-0 my-1" >
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="mx-0 px-0 py-0 my-0" >
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col>
                                <div class="text-uppercase font-weight-bold primary--text text-h6">
                                    Datos generales
                                </div>
                            </v-col>

                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field readonly label="Fecha" v-model="requisition.date"></v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field readonly label="Proyecto" v-model="requisition.project"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field readonly label="Solicitante" v-model="requisition.user"></v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field readonly label="Empresa" v-model="requisition.business"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0" cols="12" >
                                <v-textarea rows="3" readonly label="Observaciones" v-model="requisition.comment"></v-textarea>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <div class="text-uppercase font-weight-bold primary--text text-h6">
                                    Catálogo
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                
                                <v-data-table :key="tableUpdate" class="table-b-border-header" :headers="tableProps.headers" :items="requisition.concepts" disable-sort disable-pagination disable-filtering hide-default-footer> <!-- :loading="table_loading" -->


                                     <template v-slot:item.actions="item" >

                                            <RequisitionTableActions
                                                :itemInput="item.item"
                                                :parameters="item.parameters"
                                                :edit="true"
                                                @methodHandler="method_handler"
                                                :nudgeLeft="7"
                                            ></RequisitionTableActions>

                                     </template>

                                    <!-- <template v-slot:item.actions="{ item }">
                                        <v-tooltip top v-if="item.showApprove">
                                            <template v-slot:activator="{ on }">
                                                <v-btn :disabled="!item.showReject" x-small class="mx-0 " icon @click="approveProduct(item)">
                                                    <v-icon color="success" v-on="on">mdi-check-circle-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Aprobar</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="item.showReject">
                                            <template v-slot:activator="{ on }">
                                                <v-btn :disabled="!item.showApprove" x-small class="mx-0 " icon @click="rejectProduct(item)">
                                                    <v-icon color="error" v-on="on">mdi-close-circle-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Rechazar</span>
                                        </v-tooltip>
                                    </template> -->
                                </v-data-table>
                                  
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>

            </div>
			
            <snackbar :props="snackbarProps"></snackbar>

            <!-- REJECT MODAL -->
            <modal :props="modalRejectProps" @modalResponse="method_handler">
                <template slot="cardBody">
                    <v-col  class="text-center px-0 py-0">
                        <div class="text-uppercase font-weight-bold primary--text text-body pb-2">
                            <span>RECHAZAR REQUISICIÓN</span>
                        </div>
                    </v-col>
                    <span class="font-weight-bold ">
                        ESTAS A PUNTO DE RECHAZAR LA REQUISICIÓN {{id}} DE {{requisition.user}}
                        DEBES INDICAR EL MOTIVO DEL RECHAZO PARA CONCLUIR EL PROCESO.
                    </span>
                    <v-form ref="form" @submit.prevent="save">
                        <v-row class="py-2 mt-2" align="center" justify="center">
                            <v-col class="py-0">
                                <v-textarea rows="3" label="Motivo de rechazo" v-model="form.comment" :rules="rules.required"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>

                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end">
                        <v-col cols="12" sm="3" lg="2">
                            <v-btn class='full-width-btn' elevation="0"  color="transparent" @click="closeRejectModal()">
                                Regresar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn :loading="rejectBtnLoading" class='full-width-btn ml-0 ml-md-2 white-loader' elevation="0" color="red" @click="reject()">
                                <span class="white--text">Rechazar</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template> 
            </modal>

            <!-- APPROVE MODAL -->
            <modal :props="modalApproveProps" @modalResponse="method_handler">
                <template slot="cardBody">
                    <v-col  class="text-center px-0 py-0">
                        <div class="text-uppercase font-weight-bold primary--text text-body pb-2">
                            <span>APROBAR REQUISICIÓN</span>
                        </div>
                    </v-col>
                    <span class="font-weight-bold ">
                        ¿ESTÁS SEGURO QUE DESEAS APROBAR LA REQUISICIÓN {{this.id}} DEL
                        USUARIO {{requisition.user}}?
                    </span>
                   
                </template>

                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end">
                        <v-col cols="12" sm="3" lg="3">
                            <v-btn class='full-width-btn' elevation="0"  color="transparent" @click="closeApproveModal()">
                                No, Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn :loading="approveBtnLoading" class='full-width-btn ml-0 ml-md-2 white-loader' elevation="0" color="success" @click="approve()">
                                <span class="white--text">Si, Aprobar</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template> 
            </modal>



            <!-- images modal -->
            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- form -->
                <template slot="cardBody">
                    <div>
                        <v-row>
                        
                            <v-col >
                                <!-- <v-img
                                    :src="item"
                                    height="100"
                                    width="100"
                                ></v-img> -->
                                <imageSlide
                                    :mini="true"
                                    :images=" selectedItem.imagesUrls"
                                ></imageSlide>
                            </v-col>
                        </v-row>
                        
                    </div>
                </template>
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                       
                        <v-col cols="12" class="text-right">
                            <v-btn tile elevation="0" color="primary" class="ml-md-2 ml-0 mt-md-0 mt-3 btn-primary full-width-btn text-white-color" @click="()=>{this.modalProps.visible = false}">Aceptar</v-btn>
                        
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCard>
</template>

<script>
/* eslint-disable */
import NotificationManager from '../../../../helpers/NotificationManager'
import Utils from '../../../../helpers/Utils';
import RequisitionTableActions from './RequisitionTableActions.vue';
export default {
    components: {
        // 'requisition-form': RequisitionForm,
        RequisitionTableActions
    },
    watch: {
        "$route.params.id"(val) {
            this.id = val;
            // call the method which loads your initial state

            this.index();
        },
    },
	data(){
		return {
            tableUpdate: 3433,
            id: this.$route.params.id,
            loading: false,
            rejectBtnLoading:false,
            approveBtnLoading:false,
            selectedItem: {},
            edit:true,
            messagesWindowKey: 333,
            comment: "",
            serverErrors: {},
            tableSelected:[],
            requisition:{
                date: "",
                building: "",
                project: "",
                user: "",
                business: "",
                comments: "",

                concepts: []
            },
            form: {
                comment: '',
                
            },
            rules: {
                required: [
                    v => !!v || 'El campo es requerido'
                ],
                
            },
            modalProps: {
				visible: false,
				width: '700',
				title: 'Imágenes',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				customActions: true,
			},
			
			
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                cy:"accept-image-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
            actions: [
				{
					name: 'Aprobar',
					icon: {
						color: 'green',
						icon: 'mdi-check-circle-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'approveProduct',
                    permissions: 'requisitions.delete',
                    dataCy: "approve-link"
				},
                {
					name: 'Rechazar',
					icon: {
						color: 'red',
						icon: 'mdi-close-circle-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'rejectProduct',
                    permissions: 'requisitions.delete',
                    dataCy: "reject-link"
				},
                {
					name: 'Descripciones',
					icon: {
						color: 'primary',
						icon: 'mdi-message-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openComments',
                    dataCy: "descriptions-link"
				},
				{
					name: 'Imágenes',
					icon: {
						color: 'primary',
						icon: 'mdi-image-multiple-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'imagesItemModal',
                    dataCy: "images-link"
				},
			],
            tableProps: {
                headers: [
                    {
                        text: 'Codificado',
                        align: 'left ',
                        value: 'serial',
                        width: "12",
                        class: 'primary--text uppercase--text text-subtitle-2 font-weight-bold'
                    },
                    {
                        text: 'Descripción',
                        align: 'left',
                        value: 'description',
                        
                        class: 'primary--text uppercase--text text-subtitle-2 font-weight-bold'
                    },
                    {
                        text: 'Unidad',
                        align: 'center',
                        value: 'unit',
                        
                        class: 'primary--text uppercase--text text-subtitle-2 font-weight-bold'
                    },
                    {
                        text: 'Estatus',
                        align: 'center',
                        value: 'status',
                        
                        class: 'primary--text uppercase--text text-subtitle-2 font-weight-bold'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        
                        class: 'primary--text uppercase--text text-subtitle-2 font-weight-bold'
                    },
                    {
                        text: '',
                        align: 'right',
                        value: 'actions',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                   
                
                ],
            },
            modalRejectProps: {
                customActions: true,
				visible: false,
				width: '700',
				title: '',
				text: '',
                bottomLineColor: "red",
				
			},
            modalApproveProps: {
                customActions: true,
				visible: false,
				width: '700',
				title: '',
				text: '',
                bottomLineColor: "success",
				
			},
		}
    },
    mounted(){
        this.index()
    },
	methods: {
        method_handler(object){
			this[object.method_name??object.methodName](object.parameters)
		},
        back(){
            this.$router.push({ name: 'Requisiciones' });
        },
		validate () {
            console.log(this.$refs.form)
			return this.$refs.form.validate()
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        imagesItemModal(parameters)
		{
			console.log(parameters, "aqui llega");
            let found = this.requisition.concepts.find(fItem => fItem.concept_id == parameters.id)
            if(found == undefined)
                return;
            this.selectedItem = found;
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
			}
            console.log(this.selectedID, this.selectedItem, "slected")
			this.modalProps.visible = true;
			
		},
        addComments(item){
            if(this.comment == "")
                return;
            // console.log(item, "add cooment")
            // this.values.localMessages = 
            item.comments = JSON.parse(JSON.stringify(this.comment));
            if(this.edit){
                let edited = item.localMessages.find((x) => x.isNew == true);
                if(edited != undefined){
                    edited.comments = item.comments
                }else{
                    item.localMessages.push({
                        comments: item.comments,
                        date:  Utils.getNowDate(),
                        position: "right",
                        user: this.$store.state.loggedUsername,
                        isNew:true,
                    })
                }
               
            }else{
                item.localMessages = [
                    {
                        comments: item.comments,
                        date:  Utils.getNowDate(),
                        position: "right",
                        user: this.$store.state.loggedUsername,
                        isNew: true,
                    }
                ];
            }


            this.comment = "";
            this.messagesWindowKey = this.messagesWindowKey + 1;
            item.menu = false;
            console.log(item, "completo")
        },
        closeComments(){
            this.menu = false;
        },
        tobottom () {
            try {
                this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
            } catch (error) {
                console.log(error);
            }
        },
        openComments(){
            try {
                setTimeout(() => {
                    this.tobottom();
                    
                }, 100);
            } catch (error) {
                console.log(error);
            }

            return true;
        },
        index(){
            this.loading = true;
            const lastNotif = NotificationManager.getLastNotification();
            this.$http.get(`${this.$store.state.apiRoute}/requisitions/${this.id}/edit?notif=${lastNotif}`)
            .then((response) => {
                if(response.data.data.status == "Aprobada"){
                    // this.$router.push({ name: 'Requisiciones' });
                    this.$router.push({ name: 'RequisicionesEdicion', params: { id: this.id }}).catch(()=>{});
                    return;
                }

                console.log(response.data.data.date.split('/').reverse().join('-'));
                this.requisition.date = response.data.data.date;
                this.requisition.project = response.data?.data?.project;
                // this.requisition.building = response.data.data.building;
                this.requisition.user = response.data.data?.seller;
                this.requisition.business = response.data?.data?.business_text;
                this.requisition.comment = response.data.data?.comments
                this.requisition.concepts = response.data?.concepts??[];

                // this.requisition.concepts.forEach(element => {
                //      element['actions'] = this.setActions(element.status)
                //     if(element.status == "Pendiente"){
                //         element[`showApprove`] = true;
                //         element[`showReject`] = true;
                //     }else if(element.status == "Rechazada"){
                //         element[`showApprove`] = false;
                //         element[`showReject`] = true;
                //     }else if(element.status == "Aprobada"){
                //         element[`showApprove`] = true;
                //         element[`showReject`] = false;
                //     }else if(element.status == "Solicitada"){
                //         element[`showApprove`] = true;
                //         element[`showReject`] = false;
                //     }
                // });
               this.requisition.concepts = response.data?.concepts?.map((x, i) => {
                    // x.status = "Aprobada"
                    x['actions'] = this.setActions(x.status)
                    x['parameters'] = { id: x.id, name: x.concept, index: i};


                    console.log(x, "antes")
                    if(x.comments == null) {
                        x.comments = [];
                    }
                    if(x.visual_support == null) {
                        x.visual_support = [];
                    }
                    console.log(x, "desp")


                    // x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['parameters'] = { id: x.concept_id, name: x.concept, index: i};

                    x['localMessages'] = [...x?.comments.map((ms) => {return{...ms, comments: ms.comments??ms.comment, isNew:false}})];
                    x['imagesUrls'] = x?.visual_support.map((img) => {return {url:img}});
                    // x['concept_id'] = x.concept_id;
                    // x['unit'] = x.unit;
                    // x['quantity'] = x.quantity;
                    x['visual_supportBackup'] = x.visual_support;
                    x['visual_support'] = [];
                    // x['comments'] = x.comments;
                    x['name'] = x.concept;
                    x['part'] =i+1;
                    return x;
                });
            })
            .catch(error => {
                console.log(error);
                switch(error.response.status){
                       
                    case 403: 
                        this.$router.push({ name: 'Requisiciones' })
                    break;
                }
            })
            .finally(() => {
                this.loading = false;
            })
        },
        setActions(status){
            let actions = JSON.parse(JSON.stringify(this.actions));
            console.log(actions, "acitons taken")
            switch (status) {
                case "Aprobada":
                    
                    actions[0].disabled = true;
                    actions.splice(1, 1);
                    break;
                case "Solicitada":
                    actions.splice(1, 1);
                    actions[0].disabled = true;
                    actions[1].disabled = true;
                    actions[2].disabled = true;
                    break;

                case "Rechazada":
                    actions.splice(0, 1);
                    actions[0].disabled = true;
                    actions[1].disabled = false;
                    actions[2].disabled = false;
                    break;
            
            
                default:
                    break;
            }
            console.log(actions, "despues")
            return actions;
        },
        approveProduct(item)
        {
            let found = this.requisition.concepts.findIndex((x) => x.concept_id == item.id);
            console.log(found, "approveproduct")
            if(this.requisition.concepts[found].status == "Pendiente"){
                this.requisition.concepts[found].status = "Aprobada";
                this.requisition.concepts[found].actions = this.setActions(this.requisition.concepts[found].status);
            }
            // item.showReject = false;
            this.tableUpdate = this.tableUpdate + 1;
            // item.status = "Aprobada";

        },
        rejectProduct(item)
        {
            let found = this.requisition.concepts.findIndex((x) => x.concept_id == item.id);
            if(this.requisition.concepts[found].status == "Pendiente"){
                this.requisition.concepts[found].status = "Rechazada";
                this.requisition.concepts[found].actions = this.setActions(this.requisition.concepts[found].status);
            }
            // item.showApprove = false;
            this.tableUpdate = this.tableUpdate + 1;
            // item.status = "Rechazada";
        },
        save(){
            let data = this.prepareRequest("Pendiente");
            console.log(data, "data salida");
            this.approveBtnLoading = true;
            this.$http.post(`${this.$store.state.apiRoute}/requisitions/${this.id}/storeAction`, data)
            .then(() => {
                

                this.$store.state.globalSnackbarProps.visible = true
                this.$store.state.globalSnackbarProps.text = 'Se ha Aprobado la requisición'
                this.$router.push({ name: 'Requisiciones' });
            })
            .catch((error) => {
                var errors = []
                switch(error.response.status){
                    case 422: 
                        Object.keys(error.response.data.errors).forEach((x) => {
                            error.response.data.errors[x].forEach((y) => {
                                errors.push(y)
                            })
                        })
                    break;
                    case 500: 
                        errors.push('Error en el servidor. Intente de nuevo.')
                    break;
                    case 404: 
                        errors.push('No encontrado.')
                    break;
                    case 403: 
                        errors.push('Lo sentimos, acceso denegado')
                    break;
                }

                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 10000,
                    text: errors.join('.<br>'),
                }
            })
            .finally(() => {
                this.approveBtnLoading = false;
            })
            // eslint-disable-next-line no-console
        },
        openApproveModal()
        {
            this.modalApproveProps.visible = true;
        },
        closeApproveModal()
        {
            this.modalApproveProps.visible = false;
        },
        approve()
        {
            let data = this.prepareRequest("Aprobada");
            this.approveBtnLoading = true;
            this.$http.post(`${this.$store.state.apiRoute}/requisitions/${this.id}/storeAction`, data)
            .then(() => {
                

                this.$store.state.globalSnackbarProps.visible = true
                this.$store.state.globalSnackbarProps.text = 'Se ha Aprobado la requisición'
                this.$router.push({ name: 'Requisiciones' });
            })
            .catch((error) => {
                var errors = []
                switch(error.response.status){
                    case 422: 
                        Object.keys(error.response.data.errors).forEach((x) => {
                            error.response.data.errors[x].forEach((y) => {
                                errors.push(y)
                            })
                        })
                    break;
                    case 500: 
                        errors.push('Error en el servidor. Intente de nuevo.')
                    break;
                    case 404: 
                        errors.push('No encontrado.')
                    break;
                    case 403: 
                        errors.push('Lo sentimos, acceso denegado')
                    break;
                }

                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 10000,
                    text: errors.join('.<br>'),
                }
            })
            .finally(() => {
                this.approveBtnLoading = false;
            })
        },
        openRejectModal()
        {
            try {
                this.$refs.form.reset();
            } catch (error) {
                console.log(error)
            }
            this.modalRejectProps.visible = true;
        },
        closeRejectModal()
        {

            this.modalRejectProps.visible = false;
        },
        reject(){
           

            if(this.$refs.form.validate()){    
                this.rejectBtnLoading = true;  
                // var form = JSON.parse(JSON.stringify( this.form))   
                
                let data = this.prepareRequest("Rechazada");
                this.$http.post(`${this.$store.state.apiRoute}/requisitions/${this.id}/storeAction`, data)
                .then(() => {
                    

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha rechazado la requisición'
                    this.$router.push({ name: 'Requisiciones' });
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                        case 403: 
                            errors.push('Lo sentimos, acceso denegado')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.rejectBtnLoading = false;
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
            
        },


        prepareRequest(action)
        {
            let requisitionCopy = JSON.parse(JSON.stringify(this.requisition));
            let data = {action: action, comment: this.form.comment, concepts: []};

            if(action == "Aprobada"){
                if(this.tableUpdate == 3433) //the table has not been used
                {
                    requisitionCopy.concepts.forEach(c => {
                        data.concepts.push({
                            id: c.id,
                            concept_id: c.concept_id,
                            quantity: c.quantity,
                            comment: Array.isArray(c.comments) ? "" : c.comments??"",
                            status: c.status == "Pendiente" ? "Aprobada" : c.status
                        });
                    });
                }else{
                    requisitionCopy.concepts.forEach(c => {
                        data.concepts.push({
                            id: c.id,
                            concept_id: c.concept_id,
                            quantity: c.quantity,
                            comment: Array.isArray(c.comments) ? "" : c.comments??"",
                            status: c.status,
                        });
                    });
                }
            }

            if(action == "Rechazada"){
                if(this.tableUpdate == 3433) //the table has not been used
                {
                    requisitionCopy.concepts.forEach(c => {
                        data.concepts.push({
                            id: c.id,
                            concept_id: c.concept_id,
                            quantity: c.quantity,
                            comment: Array.isArray(c.comments) ? "" : c.comments??"",
                            status: c.status == "Pendiente" ? "Rechazada" : c.status
                        });
                    });
                }else{
                    requisitionCopy.concepts.forEach(c => {
                        data.concepts.push({
                            id: c.id,
                            concept_id: c.concept_id,
                            comment: Array.isArray(c.comments) ? "" : c.comments??"",
                            quantity: c.quantity,
                            status: c.status,
                        });
                    });
                }
            }


            if(action == "Pendiente"){
                data.action = "Aprobada";
                requisitionCopy.concepts.forEach(c => {
                    console.log(c, "la c")
                    data.concepts.push({
                        id: c.id,
                        concept_id: c.concept_id,
                        quantity: c.quantity,
                        comment: Array.isArray(c.comments) ? "" : c.comments??"",
                        status: c.status,
                    });
                });
            }

            return data;
        }

    },
}
</script>

<style lang="scss">
    .white-loader > .v-btn__loader{
        color: white !important;
    }
</style>