<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="4">
						<v-text-field solo flat outlined dense v-model="tableProps.search" label="Buscar..." clearable append-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col class='flexbox-right' cols="12" md="2" v-permission="'requisitions.create'">
						<v-btn class='btn-yellow full-width-btn' elevation="0" :to="{ name: 'RequisicionesRegistro' }" tile>
                            Nueva requisición
                            <v-icon
                                class="ml-2"
                                color=""
                            >
                                mdi-plus
                            </v-icon>
                        </v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable class="text-uppercase" :props="tableProps" @method_handler="method_handler">
                            <template v-slot:[`item.sku`]="{ item }">
                                <span v-if="item.status == 'Pendiente'"><v-icon small  color="red" class="requisition-status-light">mdi-circle</v-icon> {{item.sku}}</span>
                                <span v-else><v-icon small  color="" class="requisition-status-light">mdi-circle-outline</v-icon> {{item.sku}}</span>
							</template> 
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="getStatusColor(item.status)"><span class="white--text">{{item.status}}</span></v-chip>
							</template> 
							<template v-slot:[`item.delivery`]="{ item }">
                                <v-chip :color="getStatusColor(item.delivery)"><span :class="`${getStatusTextColor(item.delivery)}--text`">{{item.delivery}}</span></v-chip>
							</template> 
                        </datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

	</div>
</template>

<script>
export default {
	
	data(){
		return {
            actions: [
                {
                    name: 'Revisar',
                    icon: {
                    color: 'secondary',
                        icon: 'mdi-list-status'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'requisitions.review'
                },
                {
                    name: 'Editar',
                    icon: {
                    color: 'secondary',
                        icon: 'mdi-pencil'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'requisitions.update'
                },
                // {
                //     name: 'Borrar',
                //     icon: {
                //         color: 'secondary',
                //         icon: 'mdi-delete'
                //     },
                //     disabled: false,
                //     type: 'method',   //  method, external, redirect
                //     action: 'removeRecord',
                //     permissions: 'requisitions.delete'
                // },
                {
                    name: 'Duplicar',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-content-copy'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'requisitions.copy'
                },
                {
                    name: 'Imprimir',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-printer'
                    },
                    disabled: false,
                    type: 'external',   //  method, external, redirect
                    action: '',
                    permissions: 'requisitions.show'
                },
                {
                    name: 'Orden de compra',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-file-document'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'requisitions.convert'
                }
			],
			tableProps: {
				headers: [
                    // {
					// 	text: '',
					// 	align: 'left',
                    //     width: '10',
					// 	value: 'status_light',
					// 	class: 'primary--text text-uppercase'
					// },
					{
						text: 'Folio',
						align: 'left',
						value: 'sku',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Solicitante',
						align: 'left',
						value: 'seller',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Empresa',
						align: 'left',
						value: 'business_text',
						class: 'primary--text text-uppercase'
					},
					
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Estatus',
						align: 'left',
						value: 'status',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Entrega',
						align: 'left',
						value: 'delivery',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2})
		}
	},
	mounted(){
		this.index()
        if(this.$route.query.q != undefined)
            this.tableProps.search = this.$route.query.q
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/requisitions')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))
					x['total'] = '$'+this.numberFormat.format(x.total)
                    x['parameters'] = { id: x.sku }

					x.actions[0].action = { name: 'RequisicionesRevisar', params: { id: x.sku } }
					x.actions[1].action = { name: 'RequisicionesEdicion', params: { id: x.sku } }
					x.actions[2].action = { name: 'RequisicionesRegistro', params: { id: x.sku } }
					// x.actions[3].action = this.$store.state.apiRoute+'/requisitions/'+x.sku+'/pdf'
                    x.actions[3].action = this.$store.state.apiRoute+'/requisitions/'+x.sku+'/pdf';
					x.actions[4].action = { name: 'OrdenesDeCompraRegistro', params: { id: x.sku } }

                    if(x.status == "Aprobada"){
                        x.actions[0].disabled = true;
                    }
                    if(x.status == "Pendiente"){
                        x.actions[2].disabled = true;
                        x.actions[4].disabled = true;
                    }
                    if(x.status == "Rechazada"){
                        x.actions[2].disabled = true;
                        x.actions[4].disabled = true;
                    }

					x.delivery = x.delivery??"NA";


                    // x["status_light"] = "si";

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Eliminar requisición',
				text: '¿Desea continuar?',
				buttons: [
					{
						text: 'Cancelar',
						color: 'primary',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: {}
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'text-base-color mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: {
							id: parameters.id
						}
					}
				]
			}
			this.modalProps.visible = true
		},
		confirm(parameters){
			this.$http.delete(this.$store.state.apiRoute+'/requisitions/'+parameters.id+'/delete')
			.then(() => {
				this.snackbar = {
					visible: true,
					color: 'green',
					text: 'Se eliminó la requisición',
					timeout: 3000
				}
				this.index()
			}).catch(error => {
                console.error(error);
				this.snackbar = {
					visible: true,
					color: 'red',
					text: 'Error al eliminar. Intenta de nuevo',
					timeout: 3000
				}
			})
			.finally(() => {
				this.modalProps.visible = false
			})
		},
		cancel(){
			this.modalProps.visible = false
		},
        getStatusColor(status)
        {
            switch (status) {
                case "Aprobada":
                    return 'green'
                case "Pendiente":
                    return 'orange';
                case "Rechazada":
                    return 'red';
                case "Parcial":
                    return '#34baeb';
                default:
                    return 'primary'
            }
        },
		
        getStatusTextColor(status){
            switch (status) {
                // case "Parcial":
                //     return "black";
                    
            
                default:
                    return "white"
            }
        }
	},
}
</script>

<style lang="scss">
    .requisition-status-light{
        width: 20px;
        padding-bottom: 2px;
    }
</style>