<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Registrar nueva Orden de entrada
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <orden-entrada-form ref="OrdenEntrada" :values="form" @save="save"></orden-entrada-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
            <v-row justify="center" align="end">
				<v-col cols="12" md="8" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'OrdenesEntrada' }">Cancelar</v-btn>
					<v-btn tile elevation="0" class="ml-md-2 ml-0 mt-md-0 mt-3 btn-yellow full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
/* eslint-disable */
import OrdenEntrada from "./Form.vue";
export default {
    components: {
        'orden-entrada-form': OrdenEntrada,
    },
	data(){
		return {
            loading: false,
            form: {
                date: "",
                purchase_order_id: "",
                distributor_id: "",
                concepts:[
                    // {
                    //     "concept_id": "1",
                    //     "quantity": 10
                    // },
                ],
            },
            
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        
    },
	methods: {
		validate () {
			return this.$refs.form.validate();
		},
        save(){
            
            if(this.validate()){               
                this.$store.state.overlay = true
                let form = this.prepareRequest(this.form);
                console.log(form);
                this.$http.post(this.$store.state.apiRoute+'/inbounds/store', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true;
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente';
                    this.$router.push({name: 'OrdenesEntrada'})
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                // this.$refs.OrdenEntrada.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            for (let i = 0; i < data.concepts.length; i++) {
                data.concepts[i].quantity = request.concepts[i].pending;
                delete data.concepts[i].unit;
                delete data.concepts[i].serial;
                delete data.concepts[i].product;
                delete data.concepts[i].pending;
                delete data.concepts[i].entries;
            }


            return data;
        }
    },
}
</script>

<style>

</style>