<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center" cols="12" md="8">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            {{ id }}
                        </div>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <v-row class="px-1">
                                <v-col cols="6">
                                    <span class="font-weight-bold primary--text">Proveedor: </span> {{ detailsItems.supplier }}
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-bold primary--text">Fecha: </span> {{ detailsItems.date }}
                                </v-col>
                                <v-col cols="6">
                                    <span class="font-weight-bold primary--text">Obra: </span> {{ detailsItems.building }}
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-bold primary--text">Vendedor: </span> {{ detailsItems.seller }}
                                </v-col>
                                <v-col cols="6" class="">
                                    <span class="font-weight-bold primary--text">Empresa: </span> {{ detailsItems.business_text }}
                                </v-col>
                                <!-- <v-col class="text-right">
                                    <span class="font-weight-bold primary--text">Tipo de materia a solicitar: </span> {{ detailsItems.supplies }}
                                </v-col> -->
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <div class="divider-secondary"></div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <span class="font-weight-bold primary--text pl-1">CATÁLOGO</span>
                            <v-simple-table fixed-header min-height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left primary--text pl-1">CONCEPTO</th>
                                            <th class="text-center primary--text">UNIDAD</th>
                                            <th class="text-center primary--text">CANTIDAD</th>
                                            <th class="text-right primary--text">COSTO</th>
                                            <th class="text-right primary--text pr-1">SUBTOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, i) in concepts" :key="i" class="text-center">
                                            <td class="text-left pl-1">
                                                {{ product.concept }}
                                            </td>                                        
                                            <td>{{ product.unit }}</td>
                                            <td>{{ product.quantity }}</td>
                                            <td class="text-right">${{ numberFormat.format(product.amount) }}</td>
                                            <td class="text-right pr-1">${{ numberFormat.format(product.quantity * product.amount) }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="text-right primary--text" colspan="4">TOTAL</th>
                                            <th class="text-right primary--text subtitle-2 pr-1">${{ total }}</th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <div v-if="seguimientos.length > 0">
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <span class="font-weight-bold primary--text pl-1">SEGUIMIENTOS</span>
                            </v-col>
                        </v-row>
                        <v-row class="pl-1" justify="center" v-for="(seguimiento, i) in seguimientos" :key="i">
                            <v-col cols="12" md="8">
                                <v-row>
                                    <v-col><span class="font-weight-bold">Fecha: </span> {{ seguimiento.date }}</v-col>
                                    <v-col><span class="font-weight-bold">Próximo contacto: </span>{{ seguimiento.next_date }}</v-col>
                                    <v-spacer></v-spacer>
                                    <v-col class="text-right"><v-btn color="secondary" icon target="_blank" :href="$store.state.s3Route+seguimiento.file_path"><v-icon>mdi-attachment</v-icon></v-btn></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2"><div class="font-weight-bold">Comentario: </div></v-col>
                                    <v-col>{{ seguimiento.comments }}</v-col>
                                </v-row>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="pagos.length > 0">
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <span class="font-weight-bold primary--text pl-1">PAGOS</span>
                            </v-col>
                        </v-row>
                    </div>


                    <!-- SUMMARY -->
                    <div v-if="summary.length > 0">
                        <v-row justify="center">
                            <v-col cols="12" md="8">
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                                <p class="mb-0 pb-0">RESUMEN ÓRDENES DE ENTRADA</p>
                            </v-col>
                        </v-row>

                        <v-row justify="center" >
                            <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                                <v-simple-table fixed-header min-height="300px">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left primary--text pl-1">CONCEPTO</th>
                                                <th class="text-center primary--text">UNIDAD</th>
                                                <th class="text-center primary--text">CANTIDAD</th>
                                                <th class="text-center primary--text">PENDIENTE</th>
                                                <th class="text-center primary--text pr-1">Órdenes de entrada</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(concept, i) in summary" :key="i" class="text-center">
                                                <td class="text-left pl-1">
                                                    {{ concept.concept }}
                                                </td>                                        
                                                <td>{{ concept.unit }}</td>
                                                <td>{{ concept.quantity }}</td>
                                                <td class="text-center">{{ concept.pending }}</td>
                                                <td class="text-center pr-1">{{ concept.inbounds }}</td>
                                            </tr>
                                        </tbody>
                                       
                                    </template>
                                </v-simple-table>
                                
                            </v-col>
                        </v-row>
                    </div>
                   



                    
                </v-card-text>
                
            </div>
                    
            <v-row justify="center" align="end">
                <v-col cols="12" md="8" class="text-right">
                    <v-btn class="full-width-btn text-base-color" color="secondary" tile :to="{ name: 'OrdenesDeCompra' }">Regresar</v-btn>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
export default {
    computed: {
        total(){
            return parseFloat(this.concepts.reduce(function(a, b){
                return a + (b.quantity * b.amount);
            }, 0)).toFixed(2);
        }
    },
    data(){
        return {
            loading:true,
            id: this.$route.params.id,
            fecha: '',
            detailsItems: {
                building: '',
                supplier: '',
                // supplies: '',
                seller: '',
                date: '',
                business_text: ""
            },
            concepts: [],
            seguimientos: [],
            pagos: [],
            summary: [],
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),
           
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/purchaseOrders/'+this.id+'/show')
            .then((response) => {
                Object.keys(this.detailsItems).forEach((x) => {
                    this.detailsItems[x] = response.data.data[x]
                })

                // this.fecha = response.data.data.date
                this.concepts = response.data?.concepts??[];
                // this.seguimientos = response.data.tracing
                // this.pagos = response.data.payments
                this.summary = response.data?.summary??[];
            })
            .catch(error => {
                error
            })
            .finally(() => {
                 this.loading = false;
            })
        }
    }
}
</script>

<style>
.divider-secondary{
    background-color: #cb6120;
    height: 3px;
}
</style>