<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field
                    label="Código *"
                    v-model="values.code"
                    :rules="[...rules.required, ...rules.max3]"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field
                    label="Descripción *"
                    v-model="values.description"
                    :rules="[...rules.required]"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete
                    :items="items.businesses"
                    label="Empresa *"
                    v-model="values.business"
                    :rules="rules.required"
                    append-icon="mdi-chevron-down"
                    :loading="loadingBusiness"
                ></v-autocomplete>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ["values", "edition"],
    computed: {},
    data() {
        return {
            product_id: "",
            loadingBusiness: false,
            rules: {
                required: [(v) => !!v || "El campo es requerido"],
                max3: [
                    (v) => {
                        if (v === null || v.toString().trim() === "")
                            return "El campo es requerido";
                        if (v != null && !v.toString().match("^[0-9]{1,3}?$"))
                            return "Solo números válidos";

                        return true;
                    },
                ],
            },
            items: {
                businesses: [],
            },
        };
    },
    mounted() {
        this.getBusiness();
    },
    methods: {
        getBusiness() {
            this.loadingBusiness = true;
            this.items.businesses = [];
            this.$http
                .get(this.$store.state.flujoRoute + "/external/business")
                .then((response) => {
                    for (let i = 0; i < response.data.length; i++) {
                        const element = response.data[i];
                        if (
                            this.$store.state.userData.business.includes(
                                element.value
                            )
                        ) {
                            this.items.businesses.push({
                                text: element.name,
                                value: element.value,
                            });
                        }
                    }

                    //add the current business of the requisition
                    // if(this.edition && this.values.business != ""){
                    //     let found = this.items.businesses.find((actual) => actual.value == this.values.business);
                    //     if(found == undefined){
                    //         let addActual = response.data.find((newActual) => newActual.value == this.values.business);
                    //         if(addActual != undefined)
                    //             this.items.businesses.push({text: addActual.name, value: addActual.value});
                    //     }
                    // }

                    if (this.items.businesses.length == 1 && !this.edition) {
                        this.values.business = this.items.businesses[0].value;
                    }
                })
                .finally(() => {
                    this.loadingBusiness = false;
                });
        },
    },
    watch: {},
};
</script>

<style>
</style>