<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center" cols="12" md="8">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            {{ id }}
                        </div>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <v-row class="px-1">
                                <v-col cols="6">
                                    <span class="font-weight-bold primary--text">Distribuidor: </span> {{ detailsItems.distributor_id }}
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-bold primary--text">Fecha: </span> {{ detailsItems.date }}
                                </v-col>
                                <v-col cols="6">
                                    <span class="font-weight-bold primary--text">Orden de compra: </span> {{ detailsItems.purchase_order_id }}
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <span class="font-weight-bold primary--text">proveedor: </span> {{ detailsItems.supplier }}
                                </v-col>
                             
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <div class="divider-secondary"></div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <span class="font-weight-bold primary--text pl-1">Conceptos</span>
                            <v-simple-table fixed-header min-height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left primary--text pl-1">CONCEPTO</th>
                                            <th class="text-center primary--text">CANTIDAD</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, i) in concepts" :key="i" class="text-center">
                                            <td class="text-left pl-1">
                                                {{ product.concept }}
                                            </td>                                        
                                            <td>{{ product.quantity }}</td>
                                        </tr>
                                    </tbody>
                                    
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                   
                </v-card-text>
                
            </div>
                    
            <v-row justify="center" align="end">
                <v-col cols="12" md="8" class="text-right">
                    <v-btn class="full-width-btn text-base-color" color="secondary" tile :to="{ name: 'OrdenesEntrada' }">Regresar</v-btn>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
export default {
    computed: {
        total(){
            return parseFloat(this.concepts.reduce(function(a, b){
                return a + (b.quantity * b.amount);
            }, 0)).toFixed(2);
        }
    },
    data(){
        return {
            loading:true,
            id: this.$route.params.id,
            fecha: '',
            detailsItems: {
                purchase_order_id: '',
                distributor_id: '',
                supplier: '',
                date: '',
            },
            concepts: [],
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),

        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/inbounds/'+this.id+'/show')
            .then((response) => {
                Object.keys(this.detailsItems).forEach((x) => {
                    this.detailsItems[x] = response.data.data[x]
                })

                this.concepts = response.data.concepts
            })
            .catch(error => {
                error
            })
            .finally(() => {
                 this.loading = false;
            })
        }
    }
}
</script>

<style>
.divider-secondary{
    background-color: #cb6120;
    height: 3px;
}
</style>