<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="4">
						<v-text-field solo flat outlined dense v-model="tableProps.search" label="Buscar..." clearable append-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col class='flexbox-right' cols="12" md="2" v-permission="'concepts.create'">
						<v-btn class='btn-yellow full-width-btn' elevation="0" :to="{ name: 'OrdenesEntradaRegistro' }" tile>
                            Nueva OE
                            <v-icon
                                class="ml-2"
                                color=""
                            >
                                mdi-plus
                            </v-icon>
                        </v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable class="text-uppercase" :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

	</div>
</template>

<script>
import Utils from '../../../../helpers/Utils'
export default {
	
	data(){
		return {
            actions: [
                {
                    name: 'Detalle',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-information'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'inbounds.show'
                },
               
                
			],
			tableProps: {
				headers: [
					{
						text: 'Sku',
						align: 'left',
						value: 'sku',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Orden de entrada',
						align: 'left',
						value: 'purchase_order_id',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Proveedor',
						align: 'left',
						value: 'supplier',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			
			numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2})
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/inbounds')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'OrdenesEntradaDetalles', params: { id: x.sku } }

					x['parameters'] = { id: x.sku };
					x.date = Utils.globalDateFormat(x.date);

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		
	},
}
</script>

<style>

</style>