<template>
    <div>
        <div class="image-slider-visor mb-5" v-if="!mini">
            <v-scale-transition>
                <v-btn @click="openNew(selectedImg)" large color="transparent" depressed class="image-slider-btn  no-background-hover">
                    <v-icon class="">
                        mdi-open-in-new
                    </v-icon>
                </v-btn>
            </v-scale-transition>
           
            <v-img :aspect-ratio="16/9" :src="selectedImg"  contain height="500"></v-img>
                
        </div>
        <v-sheet
        class="mx-0 px-0"
        elevation="0"
        
    >
        <v-slide-group
            v-if="!hideGroup"
            v-model="model"
            class="pa-0 mx-0"
            center-active
            show-arrows
            
        >
            <v-slide-item
                v-for="(imageItem, index) in images" :key="index"
                v-slot="{ active, toggle }"
                 
            >
                <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    class="ma-1"
                    height="60"
                    width="60"
                    @click="(e) => {toggle(e); showImg(index)}"
                    
                >
                    <!-- <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                    > -->
                    <div>
                        <v-scale-transition>
                           
                            <v-img data-cy="image-slide-image" :src="imageItem.url" :width="60" :height="60"  :class="active ? 'image-slide-active-img': ''"  @click="openNew(selectedImg)"></v-img>
                
                        </v-scale-transition>
                    </div>
                        
                    <!-- </v-row> -->
                </v-card>
            </v-slide-item>
        </v-slide-group>
    </v-sheet>
    </div>

   

</template>
<script>
export default {
    props: ['valueInput',  'images', "hideGroup", "mini"],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                //console.log(value)
                this.$emit('valueChange', value)
            }
        }
    },
    data(){
        return{
            model: null,
            selectedImg:"",
            openUrlBtn: {
                text: "",
                icon: "mdi-eye-outline",
				to: "",
				block: false,
                textClass:"",
                click: ()=>{this.openUrl(this.url)}
            },
            deleteBtn: {
                text: "",
                icon: "mdi-delete-outline",
                textClass:"white--text",
				to: "",
				block: false,
                click: ()=>{this.delete()}
            },
        }
    },
    mounted(){
        if(this.images?.length > 0)
            this.selectedImg = this.images[0].url;
    },
    methods:{
        openUrl(fileUrl){
            window.open(fileUrl)
        },
        delete(){
            //console.log('ok delete');
            this.$emit('update:url', '');
        },
        cleared(){
            this.$emit('cleared:file','');
        },
        showImg(index){
            let item = this.images[index].url;
            this.selectedImg = item;
        },
        openNew(url){
             window.open(url)
        }
    }
    
}
</script>

<style lang="scss" >
    .image-slide-active-img{
        border: var(--v-primary-base) 2px solid ;
    }

    .image-slider{

        &-visor:hover > &-btn { transition: visibility 0s, opacity 0.5s linear; display: block;}

        &-visor{
            max-height: 500px;
            background-color: #2C2E2D;
            position: relative;

           
        }

        &-btn{
            
            display: none;
            -webkit-animation: fadeIn 0.5s;
            animation: fadeIn 0.5s;
            transform: scale(1.9);
            color:  var(--v-primary-base) !important;
            position: absolute;
            right: 0;
            z-index: 1;
            border-radius: 10px;
            margin: 9px 11px 0 0px !important;
            min-width: 0 !important;
            padding:0 !important;
            height: min-content !important;
            width: min-content !important;
            ::before {
                background-color: #2C2E2D;
                border-radius: 5px;
            }
        }


        @-webkit-keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
        
    }
</style>

