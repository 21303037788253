<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Nueva requisición
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="!loading">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <requisicion-form :values="form" :edition="false" :serverErrors="serverErrors"></requisicion-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
			<v-row justify="center" align="end">
				<v-col cols="12" md="8" class="text-right">
					<v-btn class="full-width-btn" text color="primary" :to="{ name: 'Requisiciones' }">Cancelar</v-btn>
					<v-btn tile elevation="0" class="ml-md-2 ml-0 mt-md-0 mt-3 btn-yellow full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import RequisicionForm from "./Form.vue";
import Object2Form from "../../../../helpers/Object2Form";
export default {
    components: {
        'requisicion-form': RequisicionForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: true,
            serverErrors: {},
            form: {
                // seller_id: '',
                date: '',
                // sale_order_id: '',
                project_id: '',
                // building_id: '',
                business_text: "",
                business: "",
                comments: "",
                // supplier_id: '',
                // status: 'A',
                concepts: [],
            },
           
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        console.log("ineter",  this.id)
        if(this.id != undefined)
            this.index()
        else
            this.loading = false;
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        index(){
            console.log("yes")
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/requisitions/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    business_text: response.data.data.business_text??"",
                    business: response.data.data.business??"",
                    building_id: response.data.data.building_id,
                    // supplier_id: response.data.data.supplier_id,
                    sale_order_id: response.data.data.sale_order_id+" - "+this.capitalizeFirstLetter(response.data.data.building_id.replaceAll("-", " ")),
                    date: "",
                    comments: response.data.data.comments??"",
                    concepts: response.data.concepts.map((x) => {
                        return {
                            concept_id: x.concept_id,
                            name: x.concept,
                            quantity: x.quantity,
                            unit: x.unit,
                            actions: ''
                        }
                    })
                };
                console.log(this.form)
            })
            .catch(error => {
                console.log(error);
                switch(error.response.status){
                       
                    case 403: 
                        this.$router.push({ name: 'Requisiciones' })
                    break;
                }
            })
            .finally(() => {
                this.$store.state.overlay = false;
                this.loading = false;
            })
        },
        save(){
            
            if(this.validate()){      
                this.$store.state.overlay = true
                // var form = JSON.parse(JSON.stringify( this.form)) 
                const form = this.prepareRequest(this.form);

                // form.concepts = this.form.concepts.map((x) => {
                //     return {
                //         concept_id: x.concept_id,
                //         quantity: x.quantity
                //     }
                // })
                this.$http.post(this.$store.state.apiRoute+'/requisitions/store', form)
                .then((response) => {
                    response.data
                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente la requisición <b></b>'
                    this.$router.push({ name: 'Requisiciones' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                        case 403: 
                            errors.push('Lo sentimos, acceso denegado')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    // this.form.sale_order_id = this.form.sale_order_id
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            // if(data.date == Utils.getNowDate()){
            //     data.priority = 1;
            // }

            // request.sale_order_id = request.sale_order_id.replace(/\s\s+/g, ' ');
            // data.building_id = request.sale_order_id.split('-')[1].toLowerCase().trimStart().split(' ').join('-');
            // data.sale_order_id = request.sale_order_id.split(' ')[0];
            
            for (let i = 0; i < data.concepts.length; i++) {
                delete data.concepts[i].id;
                delete data.concepts[i].unit;
                delete data.concepts[i].amount;
                delete data.concepts[i].subtotal;
                delete data.concepts[i].menu;
                delete data.concepts[i].localMessages;
                delete data.concepts[i].name;
                delete data.concepts[i].actions;
                delete data.concepts[i].parameters;
                data.concepts[i].comment = data.concepts[i].comments;
                if(request.concepts[i].visual_support.length >=1)
                    data.concepts[i].visual_support = request.concepts[i].visual_support;
                else
                    data.concepts[i].visual_support = ""
                
            }
            return Object2Form.objectToFormData(data, "", []);
        }
    },
}
</script>

<style>

</style>