<template>
    <v-dialog v-model="props.visible" persistent :width="props.width">
      <v-card>
            <v-card-title class="headline">{{ props.title }}</v-card-title>
             <v-card-text class="pre">
                {{ props.text }}
                <slot name="cardBody"></slot>
            </v-card-text>
            <v-card-actions v-if="!props.customActions">
                <v-spacer></v-spacer>
                <div v-for="(btn, i) in props.buttons" :key="i" >
                    <v-btn tile :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" @click="method_handler(btn.method, btn.parameters)">{{ btn.text }}</v-btn>
                </div>

               
            </v-card-actions>
            <v-card-actions v-else>
                <!-- <v-spacer></v-spacer>
                <div v-for="(btn, i) in props.buttons" :key="i">
                    <v-btn depressed :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" @click="method_handler(btn.method, btn.parameters)">{{ btn.text }}</v-btn>
                </div> -->
                <slot name="cardActions"></slot>
                
                
            </v-card-actions>

            <div v-if="props.bottomLineColor" :class="`h-line ${props.bottomLineColor}`"></div>
      </v-card>
    </v-dialog>    
</template>
<script>
    export default {
        props: ['props'],
        methods: {
            method_handler(method_name, parameters){
                console.log(method_name, parameters, "del modal")
                this.$emit('modalResponse', {
                    method_name: method_name, 
                    parameters: parameters
                })
            }
        }
    }
</script>
<style lang="scss">
    .pre{
        white-space: pre-line;
    }

    .h-line{
        height: 10px;
    }

    .overflow-hide{
        overflow: hidden;
    }
</style>