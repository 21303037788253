<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <!-- <v-col class="py-0">
                <v-autocomplete :items="items.sellers" label="Solicitante *" v-model="values.seller_id" :rules="rules.seller_id" :loading="loadingSellers" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col> -->
            <!-- v-col class="py-0">
                <v-autocomplete :items="items.buildings" label="Obra *" v-model="values.building_id" :rules="rules.building_id" :loading="loadingBuildings" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col -->
            <v-col class="py-0" cols="12">
                <datepicker-dialog
                    :loading="loadingDate"
                    :disabled="true"
                    :picker="dateProps"
                    :dateVal="values.date"
                    @setDate="(v) => (values.date = v)"
                    :rules="rules.date"
                ></datepicker-dialog>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete
                    :items="items.businesses"
                    label="Empresa *"
                    v-model="values.business"
                    :rules="rules.required"
                    :loading="loadingBusiness"
                    append-icon="mdi-chevron-down"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0" cols="12">
                <v-autocomplete
                    :items="items.projects"
                    label="Proyecto *"
                    v-model="values.project_id"
                    :rules="rules.required"
                    :loading="loadingProjects"
                    append-icon="mdi-chevron-down"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-textarea
                    label="Observaciones"
                    v-model="values.comments"
                    :rules="rules.comments"
                ></v-textarea>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div
                    class="text-uppercase font-weight-bold primary--text text-h5"
                >
                    Catálogo
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete
                    :items="items.products"
                    label="Concepto *"
                    v-model="product_id"
                    :loading="loadingProducts"
                    append-icon="mdi-chevron-down"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0 text-right">
                <v-btn
                    class="py-0 full-width-btn"
                    color="primary"
                    text
                    tile
                    @click="addProducts"
                    >Agregar</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table
                            :headers="tableProps.headers"
                            :items="values.concepts"
                            disable-sort
                            disable-pagination
                        >
                            <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.quantity"
                                >
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div
                                        class="primary--text"
                                        style="font-weight: 700"
                                    >
                                        {{ props.item.quantity }}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.quantity"
                                            label="Cantidad"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="item">
                                <RequisitionTableActions
                                    :itemInput="item.item"
                                    :parameters="item.parameters"
                                    :edit="edition"
                                    @methodHandler="method_handler"
                                    :nudgeLeft="7"
                                ></RequisitionTableActions>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- images modal -->
        <modal
            data-cy="index-modal"
            :props="modalProps"
            @modalResponse="method_handler"
        >
            <!-- form -->
            <template slot="cardBody">
                <div>
                    <v-row>
                        <v-col>
                            <!-- <v-img
                                :src="item"
                                height="100"
                                width="100"
                            ></v-img> -->
                            <imageSlide
                                :mini="true"
                                :images="selectedItem.imagesUrls"
                            ></imageSlide>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-2">
                            <fileInputForm
                                label="Subir imágenes"
                                :multiple="true"
                                :valueInput="selectedItem.visual_support"
                                :url="selectedItem.visual_support_url"
                                :rule="rules.imagesFilesLengthPrimary"
                                @valueChange="
                                    (v) => {
                                        selectedItem.visual_support = v;
                                        delete serverErrors.visual_support;
                                    }
                                "
                                :errorMessages="serverErrors.visual_support"
                                v-on:update:url="
                                    selectedItem.visual_support_url = $event
                                "
                                :disableDelete="true"
                                data-cy="visual_support-input"
                            ></fileInputForm>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end" data-cy="index-modal-actions">
                    <!-- <v-col cols="12" sm="4">
                        <secondaryButton
                        data-cy="cancel-modal-btn"
                        :props="cancelModal"
                        ></secondaryButton>
                    </v-col> -->
                    <v-col cols="12" class="text-right">
                        <v-btn
                            tile
                            elevation="0"
                            color="primary"
                            class="ml-md-2 ml-0 mt-md-0 mt-3 btn-primary full-width-btn text-white-color"
                            @click="
                                () => {
                                    this.modalProps.visible = false;
                                }
                            "
                            >Aceptar</v-btn
                        >
                    </v-col>
                </v-row>
            </template>
        </modal>
    </div>
</template>

<script>
import RequisitionTableActions from "./RequisitionTableActions.vue";
import Utils from "../../../../helpers/Utils";
export default {
    name: "requisition-form",
    props: ["values", "edition", "serverErrors"],
    components: {
        // ImageSlide
        RequisitionTableActions,
    },
    computed: {
        supplier_id() {
            return this.values.supplier_id;
        },
    },
    watch: {
        [`values.business`](v) {
            if (this.items.businesses.length == 0) return;

            this.values.business_text = "";
            // console.log(v, "changeeee")
            const found = this.items.businesses.find((item) => item.value == v);
            if (found != undefined) this.values.business_text = found.text;
            this.values.project_id = "";
            this.getProjects(v);
        },
    },
    data() {
        return {
            ut: Utils,
            menu: false,
            messagesWindowKey: 33,
            product_id: "",
            loadingSellers: false,
            loadingBuildings: false,
            loadingProjects: false,
            loadingSuppliers: false,
            loadingProducts: false,
            loadingDate: false,
            loadingBusiness: false,
            productSelected: {},
            selectedItem: {},
            products: [],
            comment: "",
            modalProps: {
                visible: false,
                width: "700",
                title: "Imágenes",
                text: "",
                loading: false,
                bottomLineColor: "primary",
                customActions: true,
            },
            acceptModal: {
                text: "Aceptar",
                icon: "",
                to: "",
                block: true,
                color: "primary",
                textColor: "black",
                textColorLevel: "0",
                loading: false,
                cy: "accept-image-modal-btn",
                click: () => {
                    this.modalProps.visible = false;
                },
            },
            closeCommentsBtnData: {
                text: "Aceptar",
                icon: "",
                click: () => {},
                block: false,
                loading: false,
            },
            items: {
                // sellers: [
                //     { text: 'Roberto Mercado Ugalde', value: 'Roberto Mercado Ugalde' },
                //     { text: 'Roberto Guadiana', value: 'Roberto Guadiana' },
                //     { text: 'Eduardo López', value: 'Eduardo López' },
                //     { text: 'Mario Mercado Ugalde', value: 'Mario Mercado Ugalde' },
                //     { text: 'Francisco Aguiñaga', value: 'Francisco Aguiñaga' },
                //     { text: 'Fernando Ramos', value: 'Fernando Ramos' },
                //     { text: 'Administración', value: 'Administración' },
                //     { text: 'Mónica Páez', value: 'Mónica Páez' },
                //     { text: 'Salma Vázquez', value: 'Salma Vázquez' },
                //     { text: 'Sergio Mercado Ugalde', value: 'Sergio Mercado Ugalde' },
                //     { text: 'José Serrano', value: 'José Serrano' },
                //     { text: 'Recursos Humanos', value: 'Recursos Humanos' },
                //     { text: 'Jorge Mercado Ugalde', value: 'Jorge Mercado Ugalde' },
                //     { text: 'Aidee Garay', value: 'Aidee Garay' },
                //     { text: 'Gustavo Reinteria', value: 'Gustavo Reinteria' },
                // ],
                //buildings: [],
                projects: [],
                suppliers: [],
                products: [],
                businesses: [],
            },
            dateProps: {
                visible: false,
                date: "",
                width: "300px",
                label: "Fecha",
                icon: "mdi-calendar",
                btn: {
                    cancelColor: "",
                    okColor: "primary",
                },
            },
            actions: [
                {
                    name: "Descripciones",
                    icon: {
                        color: "primary",
                        icon: "mdi-message-outline",
                    },
                    disabled: false,
                    type: "method", //  method, external, redirect
                    action: "openComments",
                    dataCy: "descriptions-link",
                },
                {
                    name: "Imágenes",
                    icon: {
                        color: "primary",
                        icon: "mdi-image-multiple-outline",
                    },
                    disabled: false,
                    type: "method", //  method, external, redirect
                    action: "imagesItemModal",
                    dataCy: "images-link",
                },
                {
                    name: "Borrar",
                    icon: {
                        color: "primary",
                        icon: "mdi-delete-outline",
                    },
                    disabled: false,
                    type: "method", //  method, external, redirect
                    action: "removeProduct",
                    dataCy: "delete-link",
                },
            ],
            tableProps: {
                headers: [
                    {
                        text: "Concepto",
                        align: "left",
                        value: "name",
                        width: "40%",
                        class: "primary--text uppercase--text",
                    },
                    {
                        text: "Unidad",
                        align: "center",
                        value: "unit",
                        width: "5%",
                        class: "primary--text uppercase--text",
                    },
                    {
                        text: "Cantidad",
                        align: "center",
                        value: "quantity",
                        width: "5%",
                        class: "primary--text uppercase--text",
                    },
                    {
                        text: "",
                        align: "right",
                        value: "actions",
                        width: "7%",
                        class: "primary--text uppercase--text",
                    },
                ],
            },
            rules: {
                required: [(v) => !!v || "La empresa es requerida"],
                building_id: [(v) => !!v || "Obra es requerido"],
                project_id: [(v) => !!v || "Orden de venta es requerido"],
                // supplier_id: [
                //     v => !!v || 'Proveedor es requerido'
                // ],
                date: [(v) => !!v || "Fecha es requerido"],
                comments: [
                    (v) =>
                        v.length <= 2000 || "El campo debe ser menor de 2000",
                ],
            },
        };
    },
    mounted() {
        this.index();
        this.getProducts();
        this.getBusiness();
        console.log(this.values, "form");
        if (this.edition) {
            // this.getProducts(this.values.supplier_id)
            this.loadProducts();
            this.getProjects(this.values.business);
        }
    },
    methods: {
        method_handler(object) {
            this[object.methodName](object.parameters);
        },
        imagesItemModal(parameters) {
            console.log(parameters, this.values.concepts);
            let found = this.values.concepts.find(
                (fItem) => fItem.concept_id == parameters.id
            );
            if (found == undefined) return;
            this.selectedItem = found;
            this.selectedID = parameters.id;
            this.modalProps = {
                ...this.modalProps,
            };
            console.log(this.selectedID, this.selectedItem, "slected");
            this.modalProps.visible = true;
        },
        loadProducts() {
            this.values.concepts = this.values.concepts.map((item, i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};

                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: {
                        id: item.concept_id,
                        name: item.concept,
                        index: i,
                    },
                    id: item.id,
                    localMessages: [
                        ...item?.comments.map((ms) => {
                            return { ...ms, isNew: false };
                        }),
                    ],
                    imagesUrls: item?.visual_support_url.map((img) => {
                        return { url: img };
                    }),
                    concept_id: item.concept_id,
                    unit: item.unit,
                    quantity: item.quantity,
                    visual_supportBackup: item.visual_support,
                    visual_support: [],
                    comments: item.comments,
                    name: item.concept,
                    part: i + 1,
                };
            });

            console.log(this.values.concepts, "supppp");
        },
        index() {
            // this.loadingBuildings = true
            // this.$http.get(this.$store.state.apiRoute+'/getBuildings')
            // .then((response) => { this.items.buildings = response.data })
            // .catch((error) => { })
            // .finally(() => { this.loadingBuildings = false })

            // this.loadingSuppliers = true
            // this.$http.get(this.$store.state.apiRoute+'/getSuppliers')
            // .then((response) => { this.items.suppliers = response.data })
            // .catch(() => { })
            // .finally(() => { this.loadingSuppliers = false })

            if (this.values.date == "" && !this.edition) {
                console.log("DATE APIII");
                this.loadingDate = true;
                this.$http
                    .get(this.$store.state.apiRoute + "/getTime")
                    .then((response) => {
                        this.values.date = response.data.date;
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.loadingDate = false;
                    });
            }
        },
        getProjects(business) {
            if (business == undefined || business == null || business == "")
                return;

            this.loadingProjects = true;
            this.$http
                .get(`${this.$store.state.apiRoute}/getProjects/${business}`)
                .then((response) => {
                    this.items.projects = response.data;
                })
                .catch(() => {})
                .finally(() => {
                    this.loadingProjects = false;
                });
        },
        getBusiness() {
            this.loadingBusiness = true;
            this.items.businesses = [];
            this.$http
                .get(this.$store.state.flujoRoute + "/external/business")
                .then((response) => {
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        const element = response.data[i];
                        if (
                            this.$store.state.userData.business.includes(
                                element.value
                            )
                        ) {
                            this.items.businesses.push({
                                text: element.name,
                                value: element.value,
                            });
                        }
                    }
                    console.log(this.items.businesses);

                    //add the current business of the requisition
                    if (this.edition && this.values.business != "") {
                        let found = this.items.businesses.find(
                            (actual) => actual.value == this.values.business
                        );
                        if (found == undefined) {
                            let addActual = response.data.find(
                                (newActual) =>
                                    newActual.value == this.values.business
                            );
                            if (addActual != undefined)
                                this.items.businesses.push({
                                    text: addActual.name,
                                    value: addActual.value,
                                });
                        }
                    }

                    if (this.items.businesses.length == 1 && !this.edition) {
                        this.values.business = this.items.businesses[0].value;
                    }
                })
                .finally(() => {
                    this.loadingBusiness = false;
                });
        },
        getProducts() {
            this.loadingProducts = true;
            this.$http
                .get(this.$store.state.apiRoute + "/getConcepts")
                .then((response) => {
                    this.items.products = response.data;
                })
                .catch(() => {})
                .finally(() => {
                    this.loadingProducts = false;
                });
        },
        getProductName(id) {
            var product = this.items.products.find((x) => x.value === id);
            return product.text.split("|").join(" | ");
        },
        getProductVariant(id) {
            let name = this.items.products.find(
                (item) => item.value == id
            ).text;
            return name;
        },
        getProductUnit(id) {
            let unit = this.items.products.find(
                (item) => item.value == id
            ).unit;
            return unit;
        },
        addProducts() {
            if (this.product_id != "") {
                var validation = this.values.concepts.findIndex(
                    (x) => x.concept_id === this.product_id
                );
                if (validation < 0) {
                    // var name = this.items.products.find(x => x.value === this.product_id)
                    // var product_variant = this.getProductName(this.product_id)
                    var product_t = {
                        id: "",
                        name: this.getProductVariant(this.product_id),
                        concept_id: this.product_id,
                        quantity: 1,
                        unit: this.getProductUnit(this.product_id),
                        amount: 0,
                        subtotal: "0",
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: {
                            id: this.product_id,
                            name: this.getProductVariant(this.product_id),
                            index: 99,
                        },
                        visual_support: [],
                        comments: "",
                    };
                    this.values.concepts.push(product_t);
                }
            }
        },
        removeProduct(id) {
            console.log(id);
            let found = this.values.concepts.findIndex(
                (element) => element.concept_id == id
            );
            this.values.concepts.splice(found, 1);
        },
        save() {
            this.$emit("save");
        },
    },
};
</script>

<style>
</style>