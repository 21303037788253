<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" v-permission="'requisitions.delete'">
                    <v-col class="text-center" cols="12" md="8">
                        <v-row>
                            <v-col cols="12" md="5" class="text-center text-md-left px-0">
                                <div class="text-uppercase font-weight-bold primary--text text-h5">
                                Editar requisición
                            </div>
                            </v-col>

                            <v-col class="px-0 mx-0">
                                <v-row justify="center" align="end">
                                    <v-col cols="12" class="text-right">
                                      
                                        <v-btn class='full-width-btn ml-0 ml-md-2 my-3 my-md-0' elevation="0" color="red" @click="removeRecord()">
                                            <span class="white--text">Eliminar</span>
                                        </v-btn>
                                        
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        
                    </v-col>
                    
                </v-row>
                <v-row justify="center" v-permission:unless="'requisitions.delete'">
                    <v-col class="text-center" >
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar requisición
                        </div>
                    </v-col>
                   
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <requisicion-form :values="form"  :edition="true" :serverErrors="serverErrors"></requisicion-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
			<v-row justify="center" align="end">
				<v-col cols="12" md="8" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'Requisiciones' }">Cancelar</v-btn>
					<v-btn tile elevation="0" class="ml-md-2 mt-md-0 mt-3 btn-yellow full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
            <modal :props="modalProps" @modalResponse="method_handler"></modal>
        </template>
    </contentCard>
</template>

<script>
/* eslint-disable */
import NotificationManager from '../../../../helpers/NotificationManager';
import Object2Form from '../../../../helpers/Object2Form';
import RequisicionForm from "./Form.vue";
export default {
    components: {
        'requisicion-form': RequisicionForm,
    },
    watch: {
        "$route.params.id"(val) {
            this.id = val;
            // call the method which loads your initial state

            this.index();
        },
    },
	data(){
		return {
            id: this.$route.params.id,
            serverErrors: {},
            loading: false,
            form: {
                building_id: '',
                // sale_order_id: '',
                project_id: '',
                // supplier_id: '',
                date: '',
                // status: 'A',
                concepts: [],
                business: "",
                business_text: "",
                comments: "",
            },
            rules: {
                seller_id: [
                    v => !!v || 'Solicitante es requerido'
                ],
                building_id: [
                    v => !!v || 'Obra es requerido'
                ],
                sale_order_id: [
                    v => !!v || 'Orden de venta es requerido'
                ],
                // supplier_id: [
                //     v => !!v || 'Proveedor es requerido'
                // ],
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
                required: [
                    v => !!v || 'La empresa es requerida'
                ],
                comments:[
                    v => v.length <= 2000  || "El campo debe ser menor de 2000"
                ]
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
            modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
		}
    },
    mounted(){
        this.index()
    },
	methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		validate () {
            console.log(this.$refs.form)
			return this.$refs.form.validate()
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        index(){
            this.loading = true;
            const lastNot = NotificationManager.getLastNotification();
            this.$http.get(`${this.$store.state.apiRoute}/requisitions/${this.id}/edit?notif=${lastNot}`)
            .then((response) => {
                console.log(response.data.data.date.split('/').reverse().join('-'));
                this.form = {
                    // building_id: response.data.data.building_id,
                    business: response.data?.data.business??"",
                    business_text: response.data.data.business_text??"",
                    project_id: response.data?.data?.project_id??"",
                    // supplier_id: response.data.data.supplier_id,
                    // sale_order_id: response.data.data.sale_order_id+" - "+this.capitalizeFirstLetter(response.data.data.building_id.replaceAll("-", " ")),
                    date: response.data.data.date.split('/').reverse().join('-'),
                    comments: response.data.data.comments??"",
                    concepts: response.data?.concepts.map((x) => {
                        if(x.comments == null) {
                            x.comments = [];
                        }
                        for (let i = 0; i < x.comments.length; i++) {
                            x.comments[i].comments = x.comments[i].comment??x.comments[i].comments;                        
                        }
                        x.visual_support_url = x.visual_support;
                        if(x.visual_support_url == null) {
                            x.visual_support_url = [];
                        }
                        x.name = x.concept;
                        x.action = ''
                        return x;
                    })
                };
                console.log(this.form);         
            })
            .catch(error => {
                switch(error.response.status){
                       
                    case 403: 
                        this.$router.push({ name: 'Requisiciones' })
                    break;
                }
            })
            .finally(() => {
                this.loading = false;
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){      
               const form = this.prepareRequest(this.form);
                
                this.$http.post(this.$store.state.apiRoute+'/requisitions/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha actualizado la requisición <b>'+this.id+'</b>'
                    this.$router.push({ name: 'Requisiciones' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                        case 403: 
                            errors.push('Lo sentimos, acceso denegado')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        },
        removeRecord(){
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Eliminar requisición',
				text: '¿Desea continuar?',
				buttons: [
					{
						text: 'Cancelar',
						color: 'primary',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: {}
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'text-base-color mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: {
							id: this.id
						}
					}
				]
			}
			this.modalProps.visible = true
		},
		confirm(parameters){
			this.$http.delete(this.$store.state.apiRoute+'/requisitions/'+parameters.id+'/delete')
			.then(() => {
				this.$router.push({ name: 'Requisiciones' })
			}).catch(error => {
                switch(error.response.status){
                       
                    case 403: 
                        this.snackbarProps = {
                            visible: true,
                            color: 'red',
                            text: 'Lo sentimos, acceso denegado',
                            timeout: 3000
                        }
                    return;
                }
				this.snackbarProps = {
					visible: true,
					color: 'red',
					text: 'Error al eliminar. Intenta de nuevo',
					timeout: 3000
				}
			})
			.finally(() => {
				this.modalProps.visible = false
			})
		},
		cancel(){
			this.modalProps.visible = false
		},
        prepareRequest(request)
        {
           
            let data = JSON.parse(JSON.stringify(request));
            console.log(data, "la data")
            // if(data.date == Utils.getNowDate()){
            //     data.priority = 1;
            // }
            // request.sale_order_id = request.sale_order_id.replace(/\s\s+/g, ' ');
            // data.building_id = request.sale_order_id.split('-')[1].toLowerCase().trimStart().split(' ').join('-');
            // data.sale_order_id = request.sale_order_id.split(' ')[0];
            
            
            for (let i = 0; i < data.concepts.length; i++) {
                console.log(data.concepts[i], "el comment")
                delete data.concepts[i].amount;
                delete data.concepts[i].subtotal;
                delete data.concepts[i].name;
                delete data.concepts[i].actions;
                delete data.concepts[i].parameters;
                delete data.concepts[i].menu;
                delete data.concepts[i].unit;
                delete data.concepts[i].part;
                // data.concepts[i].comment = data.concepts[i].comments;
                // delete data.concepts[i].comments;
                data.concepts[i].visual_support = []
                if(request.concepts[i].visual_support.length >=1)
                    data.concepts[i].visual_support = request.concepts[i].visual_support;
                else
                    data.concepts[i].visual_support = null;
                    // delete data.concepts[i].visual_support;
                
                if(data.concepts[i].visual_support == null || data.concepts[i].visual_support.length == 0){
                    data.concepts[i].visual_support = null
                }

                if(Array.isArray(data.concepts[i].comments)){
                    data.concepts[i].comment = ""
                }else{
                    data.concepts[i].comment = data.concepts[i].comments;
                }
                // data.concepts[i].comments =  data.concepts[i].comments;

                delete data.concepts[i].localMessages;
                delete data.concepts[i].imagesUrls;
                delete data.concepts[i].visual_supportBackup;
                
            }
            return Object2Form.objectToFormData(data, "", []);
        }
    },
}
</script>

<style>

</style>