<template>
    <div>
        <v-row>
            <v-col class="py-0">
                <datepicker-dialog
                    :picker="dateProps"
                    :dateVal="values.date"
                    @setDate="(v) => (values.date = v)"
                    :rules="rules.required"
                    label="Fecha *"
                ></datepicker-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete
                    :items="items.purchaseOrders"
                    label="Orden de compra *"
                    v-model="values.purchase_order_id"
                    :rules="rules.required"
                    :loading="loadingPO"
                    append-icon="mdi-chevron-down"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete
                    :items="items.suppliers"
                    label="Distribuidor *"
                    v-model="values.distributor_id"
                    :rules="rules.required"
                    :loading="loadingSuppliers"
                    append-icon="mdi-chevron-down"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table
                            :headers="tableProps.headers"
                            :items="values.concepts"
                            disable-sort
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item.pending="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.pending"
                                >
                                    <div
                                        class="primary--text"
                                        style="font-weight: 700"
                                    >
                                        {{ props.item.pending }}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.pending"
                                            label="Cantidad"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            x-small
                                            class="mx-0"
                                            icon
                                            @click="
                                                removeProduct(item.concept_id)
                                            "
                                        >
                                            <v-icon color="secondary" v-on="on"
                                                >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "proveedor-form",
    props: ["values", "edition", "edit"],
    watch: {
        [`values.purchase_order_id`](v) {
            this.getSuppliersByPO(v);
            this.getPurchaseOrdersDetails(v);
        },
    },
    data() {
        return {
            loadingPO: false,
            loadingSuppliers: false,
            loadingPODetails: false,
            items: {
                purchaseOrders: [],
                suppliers: [],
            },
            rules: {
                required: [(v) => !!v || "El campo es requierido"],
            },
            dateProps: {
                visible: false,
                date: "",
                width: "300px",
                label: "Fecha *",
                icon: "mdi-calendar",
                btn: {
                    cancelColor: "",
                    okColor: "primary",
                },
            },

            tableProps: {
                headers: [
                    {
                        text: "Concepto",
                        align: "left",
                        value: "product",
                        width: "40%",
                        class: "primary--text uppercase--text",
                    },
                    {
                        text: "Pendiente",
                        align: "center",
                        value: "pending",
                        class: "primary--text uppercase--text",
                    },
                    {
                        text: "Cantidad",
                        align: "center",
                        value: "quantity",
                        class: "primary--text uppercase--text",
                    },

                    {
                        text: "",
                        align: "right",
                        value: "actions",
                        width: "5%",
                        class: "primary--text uppercase--text",
                    },
                ],
            },
        };
    },
    mounted() {
        this.getPurchaseOrders();
    },
    methods: {
        loadSelectedConcepts(selected) {
            if (this.tableProps.headers.length == 7)
                this.tableProps.headers.splice(2, 1);
            if (selected == "all") {
                this.values.concepts = JSON.parse(
                    JSON.stringify(
                        this.conceptsBackup.filter(
                            (item) => item.status != "Rechazada"
                        )
                    )
                );
                this.tableProps.headers.splice(2, 0, {
                    text: "Estatus",
                    align: "center",
                    value: "status",
                    width: "5%",
                    class: "primary--text uppercase--text",
                });
                // console.log(numbers);
            }
            if (selected == "approved") {
                this.values.concepts = JSON.parse(
                    JSON.stringify(
                        this.conceptsBackup.filter(
                            (item) => item.status == "Aprobada"
                        )
                    )
                );
            }
            if (selected == "requested") {
                this.values.concepts = JSON.parse(
                    JSON.stringify(
                        this.conceptsBackup.filter(
                            (item) => item.status == "Solicitada"
                        )
                    )
                );
            }

            console.log(this.values.concepts, this.conceptsBackup);
        },
        getPurchaseOrders() {
            this.items.purchaseOrders = [];

            this.loadingPO = true;
            this.$http
                .get(`${this.$store.state.apiRoute}/purchaseOrders`)
                .then((response) => {
                    this.items.purchaseOrders = response.data.map((itm) => {
                        return { text: itm.sku, value: itm.sku };
                    });
                })
                .catch(() => {})
                .finally(() => {
                    this.loadingPO = false;
                });
        },
        getSuppliersByPO(po = "") {
            if (po == undefined || po == null || po == "") return;

            this.items.suppliers = [];

            this.loadingSuppliers = true;
            this.$http
                .get(`${this.$store.state.apiRoute}/getDistributors/${po}`)
                .then((response) => {
                    this.items.suppliers = response.data;
                })
                .catch(() => {})
                .finally(() => {
                    this.loadingSuppliers = false;
                });
        },

        changeSubtotal(id) {
            let found = this.values.concepts.findIndex(
                (element) => element.concept_id == id
            );
            this.values.concepts[found].subtotal = (
                parseFloat(this.values.concepts[found].amount) *
                parseFloat(this.values.concepts[found].quantity)
            ).toFixed(2);
            console.log(this.values.concepts[found].subtotal);
        },

        getPurchaseOrdersDetails(po) {
            if (po == null || po == undefined || po == "") return;

            this.values.concepts = [];

            this.loadingPODetails = true;
            this.$http
                .get(
                    `${this.$store.state.apiRoute}/getPurchaseOrderDetails/${po}`
                )
                .then((response) => {
                    this.values.concepts = response.data.map((x) => {
                        return x;
                    });
                })
                .catch(() => {})
                .finally(() => {
                    this.loadingPODetails = false;
                });
        },

        removeProduct(id)
        {
            let found = this.values.concepts.findIndex(element => element.concept_id == id);
            this.values.concepts.splice(found, 1);
        },

        save() {
            this.$emit("save");
        },
    },
};
</script>

<style>
</style>